import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper } from './agentCardElements'
import Spinner from '../Spinner'
import agents from './agents.json'

/**
 * Retrieves agent information from the `agents` JSON based on agent Id.
 *
 * @param {string} agentId - Id of the agent returned from Galactica's API.
 * @returns {object} Agent information object if found, otherwise generic object named "undefined".
 */
export const getAgentFromId = (agentId) => {
  return agents[agentId] ?? agents['undefined']
}

/**
 * @param {object} props - React props for AgentCard.
 * @param {string} props.agentId - Id of the agent returned from Galactica's API.
 * @returns {JSX.Element} JSX component representing the agent card.
 */
const AgentCard = ({ agentId }) => {
  const { t } = useTranslation()
  const [isImageReady, setIsImageReady] = useState(false)
  const information = getAgentFromId(agentId)

  /**
   * Handles the image load event, setting `isImageReady` to true.
   */
  const handleImageLoaded = () => {
    setIsImageReady(true)
  }

  /**
   * Handles the image load error event, logging an error message.
   */
  const handleImageError = (e) => {
    console.error(`error: failed to load image for agent with id ${agentId}`)
    e.target.src = require(`../../assets/images/logo_placeholder.png`)
    setIsImageReady(true)
  }

  if (!information) {
    return (
      <Wrapper>
        <Spinner />
        <div>
          <h1>{t('contact.undefined')}</h1>
          <h2 style={{ visibility: 'hidden' }}>undefined</h2>
          <a style={{ visibility: 'hidden' }}>undefined</a>
          <a style={{ visibility: 'hidden' }}>undefined</a>
        </div>
      </Wrapper>
    )
  }

  let agentImage
  try {
    agentImage = `https://onitsoft-static-apps.s3.eu-central-1.amazonaws.com/aprest-nieruchomosci/agents/${agentId}.webp`
  } catch (error) {
    agentImage = `../../assets/Logo_COLOR1.png`
  }

  return (
    <Wrapper>
      {!isImageReady && <Spinner />}
      <img
        src={agentImage}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        style={{ display: isImageReady ? 'block' : 'none' }}
      />
      <div>
        <h1>{t('contact.' + information.titleKey)}</h1>
        <h2>{information.name}</h2>
        <a href={'tel:' + information.phone.replace(/\+|\s/g, '')}>{information.phone}</a>
        <a href={'mailto:' + information.email}>{information.email}</a>
      </div>
    </Wrapper>
  )
}

export default AgentCard
